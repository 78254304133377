<template>
  <div>
    <div class="card">
      <div class="card-header" id="heading-backbone">
        <h2 class="mb-0">
          <button
            class="btn btn-info collapsed"
            v-bind:disabled="
              resetMessage || disabledState.backbone == 'disabled'
            "
            type="button"
            data-toggle="collapse"
            data-target="#collapse-backbone"
            aria-expanded="false"
            aria-controls="collapse-backbone"
          >
            <span
              v-if="processing === 'backbone'"
              class="spinner-border spinner-border-sm text-light"
            ></span>
            Backbone
          </button>
        </h2>
      </div>
      <div
        id="collapse-backbone"
        class="collapse"
        aria-labelledby="heading-backbone"
        data-parent="#accordion-design"
      >
        <div class="card-body">
          <div class="container">
            <div class="row justify-content-center my-2">
              <div class="form-group">
                <legend>Plasmid</legend>
                <div class="row justify-content-center mb-2">
                  <div class="col col-md-2">
                    <label class="btn btn-lg btn-light">
                      <input
                        type="checkbox"
                        autocomplete="off"
                        v-model="configPlasmid"
                      />
                      <font-awesome-icon class="ml-2 text-info" icon="cog">
                      </font-awesome-icon>
                      <span class="mx-1 small">Configure</span>
                    </label>
                  </div>
                  <div v-if="configPlasmid" class="col col-md-8">
                    <treeselect
                      class="vue-treeselect"
                      v-model="selectedPlasmidID"
                      :multiple="false"
                      :disableFuzzyMatching="true"
                      :disable-branch-nodes="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :async="true"
                      :load-options="loadOptions"
                      @search-change="searchChange"
                      placeholder="Select a backbone plasmid ..."
                    />
                    <!-- <span>selected = {{ selectedPlasmidID }}</span> -->
                    <div v-if="plasmid" class="my-3">
                      <div class="card">
                        <div class="card-body">
                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <span class="mr-1">OLAB ID:</span>
                              <span class="ml-1">{{ plasmid.olab_id }}</span>
                            </div>
                            <div class="form-group col-md-6">
                              <span class="mr-1">Length:</span>
                              <span class="ml-1">{{
                                plasmid.stats.total_seq_len
                              }}</span>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-12">
                              <span class="mr-1">Name:</span>
                              <span class="ml-1">{{ plasmid.name }}</span>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <span class="mr-1">Creator:</span>
                              <span class="ml-1">{{ plasmid.creator }}</span>
                            </div>
                            <div class="form-group col-md-6">
                              <span class="mr-1">Source:</span>
                              <span class="ml-1">{{ plasmid.source }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="col col-md-8">
                    <div class="container">
                      <div class="row justify-content-center">
                        <div class="col">
                          <div class="alert alert-info" role="alert">
                            Design without backbone is ready for preview
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- .form-group -->
            </div>
            <div v-if="configPlasmid && filteredGenes" class="my-2">
              <legend class="mt-2">Configuration Type</legend>
              <div class="container  mb-3">
                <div class="row justify-content-center">
                  <div class="col-4">
                    <label class="btn btn-lg btn-light">
                      <input
                        type="radio"
                        value="GIBSON"
                        v-model="configAssemblyMode"
                      />
                      Gibson Assembly
                    </label>
                  </div>
                  <div class="col-4">
                    <label class="btn btn-lg btn-light">
                      <input
                        type="radio"
                        value="RESTRICTION_ENZYME"
                        v-model="configAssemblyMode"
                      />
                      Restriction Enzyme
                    </label>
                  </div>
                </div>
              </div>
              <div
                v-if="configAssemblyMode === 'RESTRICTION_ENZYME'"
                class="my-2"
              >
                <legend class="mt-2">Restriction Enzyme</legend>
                <div class="row">
                  <div class="col-12 col-md-6 my-2">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">Cut Site Filters</h5>
                        <div class="btn-group-lg btn-group-toggle">
                          <label class="h6 mr-2">Number:</label>
                          <label class="btn-lg btn-light">
                            <input
                              type="radio"
                              value="one_cs"
                              autocomplete="off"
                              v-model="enzymeCSNum"
                            />
                            <!-- <span class="mx-1 small">1</span> -->
                            <span><OlabSingleCutSiteSVGGen /></span>
                          </label>
                          <label class="btn-lg btn-light">
                            <input
                              type="radio"
                              value="two_cs"
                              autocomplete="off"
                              v-model="enzymeCSNum"
                            />
                            <!-- <span class="mx-1 small">2</span> -->
                            <span><OlabDoubleCutSiteSVGGen /></span>
                          </label>
                          <label class="btn-lg btn-light">
                            <input
                              type="radio"
                              value="any_cs"
                              autocomplete="off"
                              v-model="enzymeCSNum"
                            />
                            <!-- <span class="mx-1 small">Any</span> -->
                            <span><OlabAnyCutSiteSVGGen /></span>
                          </label>
                        </div>
                        <div class="btn-group-lg btn-group-toggle">
                          <label class="h6 mr-2">End:</label>
                          <label class="btn-lg btn-light">
                            <input
                              type="checkbox"
                              autocomplete="off"
                              v-model="enzymeCSBluntIn"
                            />
                            <!-- <span class="mx-1 small">Blunt In</span> -->
                            <span><OlabBluntInSVGGen /></span>
                          </label>
                          <label class="btn-lg btn-light">
                            <input
                              type="checkbox"
                              autocomplete="off"
                              v-model="enzymeCSBluntOut"
                            />
                            <!-- <span class="mx-1 small">Blunt Out</span> -->
                            <span><OlabBluntOutSVGGen /></span>
                          </label>
                          <label class="btn-lg btn-light">
                            <input
                              type="checkbox"
                              autocomplete="off"
                              v-model="enzymeCSStickyIn"
                            />
                            <!-- <span class="mx-1 small">Sticky In</span> -->
                            <span><OlabStickyInSVGGen /></span>
                          </label>
                          <label class="btn-lg btn-light">
                            <input
                              type="checkbox"
                              autocomplete="off"
                              v-model="enzymeCSStickyOut"
                            />
                            <!-- <span class="mx-1 small">Sticky Out</span> -->
                            <span><OlabStickyOutSVGGen /></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 my-2">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">
                          Selection
                        </h5>
                        <label class="form-control-label">
                          Restriction Enzyme [{{ filteredResEnzymes.length }}
                          counts]
                        </label>
                        <div v-bind:style="resEnzymeStyle">
                          <treeselect
                            class="vue-treeselect"
                            v-model="resEnzymeArr"
                            :multiple="true"
                            :disableFuzzyMatching="true"
                            :disable-branch-nodes="true"
                            :clear-on-select="true"
                            :close-on-select="true"
                            :options="filteredResEnzymes"
                            @select="resEnzymeSelectChange"
                            placeholder="Select a restriction enzyme ..."
                          />
                        </div>
                        <!-- <span>selected = {{ resEnzymeArr }}</span>
                        <span v-if="selectedEnzyme1"
                          >selected = {{ enzyme1Display }}</span
                        > -->
                        <div class="mt-3 row">
                          <label
                            for="enzyme_1"
                            class="h5 col col-form-label text-left"
                            >{{ enzyme1Display }}</label
                          >
                        </div>
                        <div class="row">
                          <label
                            for="enzyme_2"
                            class="h5 col col-form-label text-left"
                            >{{ enzyme2Display }}</label
                          >
                        </div>
                        <div
                          v-if="disabledState.preview === ''"
                          class="row justify-content-center"
                        >
                          <div class="alert alert-info" role="alert">
                            Design with backbone is ready for preview
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-6 my-2">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">Cut Type</h5>
                        <div class="btn-group-lg btn-group-toggle">
                          <label class="btn-lg btn-light">
                            <input
                              type="radio"
                              value="single"
                              autocomplete="off"
                              v-model="cutType"
                            />
                            <span><OlabSingleCutSVGGen /></span>
                          </label>
                          <label class="btn-lg btn-light">
                            <input
                              type="radio"
                              value="double"
                              autocomplete="off"
                              v-model="cutType"
                            />
                            <span><OlabDoubleCutSVGGen /></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 my-2">
                    <div class="card">
                      <div class="card-body">
                        <!-- <h5 class="card-title">Cut Direction</h5> -->
                        <h5 class="card-title">Backbone Part</h5>
                        <div class="btn-group-lg btn-group-toggle">
                          <label class="btn-lg btn-light">
                            <input
                              type="radio"
                              value="clockwise"
                              autocomplete="off"
                              v-model="cutDirection"
                              v-bind:disabled="cutType === 'single'"
                            />
                            <span><OlabCWCutSVGGen /></span>
                          </label>
                          <label class="btn-lg btn-light">
                            <input
                              type="radio"
                              value="counter_clockwise"
                              autocomplete="off"
                              v-model="cutDirection"
                              v-bind:disabled="cutType === 'single'"
                            />
                            <span><OlabCCWCutSVGGen /></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="configAssemblyMode === 'GIBSON'" class="my-2">
                <legend class="mt-2">Gibson Assembly</legend>
                <div class="container">
                  <section>
                    <ul class="nav nav-tabs" id="seqTab" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="hom-l-seq-tab"
                          data-toggle="tab"
                          href="#hom-l-seq"
                          role="tab"
                          aria-controls="hom-l-seq"
                          aria-selected="true"
                          >Hom L
                          <font-awesome-icon
                            v-if="homLSeqValid === false"
                            class="text-danger"
                            icon="exclamation-circle"
                          />
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="hom-r-seq-tab"
                          data-toggle="tab"
                          href="#hom-r-seq"
                          role="tab"
                          aria-controls="hom-r-seq"
                          aria-selected="false"
                          >Hom R
                          <font-awesome-icon
                            v-if="homRSeqValid === false"
                            class="text-danger"
                            icon="exclamation-circle"
                          />
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content" id="seqTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="hom-l-seq"
                        role="tabpanel"
                        aria-labelledby="hom-l-seq-tab"
                      >
                        <div class="form-group mt-3">
                          <textarea
                            class="form-control"
                            :class="homLSeqValidState"
                            v-model="inputHomLSeq"
                          ></textarea>
                          <div class="invalid-feedback">
                            {{ invalidSeqMessage }}
                          </div>
                          <div
                            v-if="
                              homLSeqValid && homLSeq && homLOption !== 'direct'
                            "
                            class="text-left text-wrap"
                          >
                            Converted HomL Sequence: {{ homLSeq }}
                          </div>
                          <div v-if="homLSeqValid && homLSeq">
                            <p class="form-text text-muted">
                              {{ homLSeq.length }}
                            </p>
                            <div class="row">
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  id="homLDirect"
                                  value="direct"
                                  v-model="homLOption"
                                />
                                <label class="form-check-label" for="homLDirect"
                                  >Direct</label
                                >
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  id="homLReverseComplement"
                                  value="reverse_complement"
                                  v-model="homLOption"
                                />
                                <label
                                  class="form-check-label"
                                  for="homLReverseComplement"
                                  >Reverse Complement</label
                                >
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  id="homLReverse"
                                  value="reverse"
                                  v-model="homLOption"
                                />
                                <label
                                  class="form-check-label"
                                  for="homLReverse"
                                  >Reverse</label
                                >
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  id="homLComplement"
                                  value="complement"
                                  v-model="homLOption"
                                />
                                <label
                                  class="form-check-label"
                                  for="homLComplement"
                                  >Complement</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="hom-r-seq"
                        role="tabpanel"
                        aria-labelledby="hom-r-seq-tab"
                      >
                        <div class="form-group mt-3">
                          <textarea
                            class="form-control"
                            :class="homRSeqValidState"
                            v-model="inputHomRSeq"
                          ></textarea>
                          <div class="invalid-feedback">
                            {{ invalidSeqMessage }}
                          </div>
                          <div
                            v-if="
                              homRSeqValid && homRSeq && homROption !== 'direct'
                            "
                            class="text-left text-wrap"
                          >
                            Converted HomR Sequence: {{ homRSeq }}
                          </div>
                          <div v-if="homRSeqValid && homRSeq">
                            <p class="form-text text-muted">
                              {{ homRSeq.length }}
                            </p>
                            <div v-if="homRSeqValid" class="row">
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  id="homRDirect"
                                  value="direct"
                                  v-model="homROption"
                                />
                                <label class="form-check-label" for="homRDirect"
                                  >Direct</label
                                >
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  id="homRReverseComplement"
                                  value="reverse_complement"
                                  v-model="homROption"
                                />
                                <label
                                  class="form-check-label"
                                  for="homRReverseComplement"
                                  >Reverse Complement</label
                                >
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  id="homRReverse"
                                  value="reverse"
                                  v-model="homROption"
                                />
                                <label
                                  class="form-check-label"
                                  for="homRReverse"
                                  >Reverse</label
                                >
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  id="homRComplement"
                                  value="complement"
                                  v-model="homROption"
                                />
                                <label
                                  class="form-check-label"
                                  for="homRComplement"
                                  >Complement</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section>
                    <!-- <span v-if="selectedEnzyme1"
                      >selected = {{ enzyme1Display }}</span
                    >
                    <span v-if="selectedEnzyme2"
                      >, selected = {{ enzyme2Display }}</span
                    > -->
                    <div class="mt-3 row">
                      <label class="h5 col col-form-label text-left">
                        {{ enzyme1Display }}
                      </label>
                    </div>
                    <div class="row">
                      <label class="h5 col col-form-label text-left">
                        {{ enzyme2Display }}
                      </label>
                    </div>
                    <div
                      v-if="disabledState.preview === ''"
                      class="row justify-content-center"
                    >
                      <div class="alert alert-info" role="alert">
                        Design with backbone is ready for preview
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div v-if="configPlasmid && filteredGenes" class="my-3 overflow-auto">
            <div id="d3svgCircularMapDBB">
              <div id="tooltipDBB" class="text-info text-left">
                <div class="display-name"></div>
                <div class="display-start">Start: <span></span></div>
                <div class="display-end">End: <span></span></div>
                <div class="display-length">Length: <span></span></div>
                <div class="display-strand">Strand: <span></span></div>
              </div>
              <div id="enzymeTooltip" class="text-info text-left">
                <div class="display-name"></div>
                <div class="display-cutsite">Cut Site: <span></span></div>
              </div>
            </div>

            <div class="container">
              <div class="row justify-content-left">
                <div class="col-10 col-md-6 my-2">
                  <button
                    class="btn btn-primary"
                    type="button"
                    data-toggle="collapse"
                    data-target="#d3LinearMap"
                    aria-expanded="false"
                    aria-controls="d3LinearMap"
                    @click="setViewSequence()"
                  >
                    View Sequence
                  </button>
                </div>
                <div class="col-10 col-md-6 my-2">
                  <div class="form-group">
                    <!-- <label class="form-control-label">
                      Gene [{{ filteredGenes.length }} counts]
                    </label> -->
                    <div class="row">
                      <span>
                        <label class="h5 form-control-label mx-2 mt-1">
                          Gene :
                        </label>
                      </span>
                      <span>
                        <select
                          class="form-control"
                          id="selectedGeneDName"
                          v-model="selectedGeneDName"
                        >
                          <option disabled value="">Select a gene</option>
                          <option
                            v-for="gn in filteredGenes"
                            v-bind:key="gn.olab_id"
                          >
                            {{ gn.d_name }}
                          </option>
                        </select>
                      </span>
                      <span class="mx-2 mt-1">
                        [{{ filteredGenes.length }} counts]
                      </span>

                      <!-- <div>{{ selectedGeneDName }}</div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <div class="overflow-auto collapse" id="d3LinearMap">
                    <div
                      class="card-header text-light"
                      v-bind:style="linearMapHeaderStyle"
                    >
                      {{ d3GeneID }}
                    </div>
                    <div class="card-body">
                      <div v-if="selectedD3GeneData" class="row text-left">
                        <div class="col">
                          <span class="ml-2">Name:</span>
                          <span class="ml-1">{{
                            selectedD3GeneData.name
                          }}</span>
                        </div>
                      </div>
                      <div v-if="selectedD3GeneData" class="row text-left">
                        <div class="col">
                          <span class="ml-2">Start (in plasmid):</span>
                          <span class="ml-1">{{
                            selectedD3GeneData.geneFrom
                          }}</span>
                        </div>
                        <div class="col">
                          <span class="ml-2">End (in plasmid):</span>
                          <span class="ml-1">{{
                            selectedD3GeneData.geneTo
                          }}</span>
                        </div>
                      </div>
                      <div v-if="selectedD3GeneData" class="row text-left">
                        <div class="col">
                          <span class="ml-2">Length:</span>
                          <span class="ml-1">{{ selectedD3GeneData.len }}</span>
                        </div>
                        <div class="col">
                          <span class="ml-2">Strand:</span>
                          <span class="ml-1">{{ d3GeneStrand }}</span>
                        </div>
                      </div>
                      <div v-if="selectedD3GeneData" class="row">
                        <div
                          v-if="
                            geneNotFound &&
                              plasmid &&
                              plasmid.ra_locks &&
                              plasmid.ra_locks.public === false
                          "
                          class="my-5 h5 col text-center"
                        >
                          <span class="ml-2">Sequence Access Denied:</span>
                          <span class="text-danger ml-1">Member Only</span>
                        </div>
                      </div>
                      <div class="row justify-content-center">
                        <div class="col-12 mt-3 mb-3">
                          <div class="card">
                            <div class="card-header text-dark">
                              Display
                            </div>
                            <div class="card-body">
                              <div class="row justify-content-center">
                                <div class="col-12 col-md-6">
                                  <div class="form-group">
                                    <div class="mr-1">
                                      Start (in gene)
                                    </div>
                                    <input
                                      class="form-control"
                                      type="number"
                                      step="1"
                                      v-bind:class="displayStartValidState"
                                      v-model="displayData.start"
                                    />
                                    <div class="invalid-feedback">
                                      {{ displayData.startInvalidFeedback }}
                                    </div>
                                  </div>
                                  <!-- .form-group -->
                                </div>
                                <div class="col-12 col-md-6">
                                  <div class="form-group">
                                    <!-- Range is renamed as Length -->
                                    <div class="mr-1">
                                      Length
                                    </div>
                                    <input
                                      class="form-control"
                                      type="number"
                                      min="1"
                                      max="5000"
                                      step="1"
                                      v-bind:class="displayRangeValidState"
                                      v-model="displayData.range"
                                    />
                                    <div class="invalid-feedback">
                                      {{ displayData.rangeInvalidFeedback }}
                                    </div>
                                  </div>
                                  <!-- .form-group -->
                                </div>
                              </div>
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend">
                                  <span
                                    class="input-group-text"
                                    id="search-wrapping"
                                    >Search</span
                                  >
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="searchStr"
                                  placeholder="Search sequence"
                                  aria-label="Search"
                                  aria-describedby="search-wrapping"
                                />
                                <span
                                  v-if="
                                    searchStrHitArr &&
                                      searchStrHitArr.length > 0
                                  "
                                  class="ml-2 text-info text-sm"
                                >
                                  {{ searchStrHitArr.length }}
                                </span>
                              </div>
                              <div class="mt-2 float-right">
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  @click="copySelectSeq"
                                >
                                  Copy
                                </button>
                              </div>
                              <div
                                id="d3svgLinearMap"
                                style="width: 600px; height: 600px; overflow-y: scroll"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { OlabAxios } from "@/olab/olabaxios.js";
import { OlabUtils } from "@/olab/olabutils.js";
import { OlabSearch } from "@/olab/olabsearch.js";
import { OlabBlock } from "@/olab/olabblock.js";
import { MetaMap } from "@/olab/d3/metaMap.js";
import { OlabCircularMap } from "@/olab/d3/olabCircularMap";
import { OlabLinearMap } from "@/olab/d3/olabLinearMap";
import { OlabPrimer } from "@/olab/olabprimer";
import OlabDoubleCutSVGGen from "@/components/OlabDoubleCutSVGGen";
import OlabSingleCutSVGGen from "@/components/OlabSingleCutSVGGen";
import OlabCWCutSVGGen from "@/components/OlabCWCutSVGGen";
import OlabCCWCutSVGGen from "@/components/OlabCCWCutSVGGen";
import OlabSingleCutSiteSVGGen from "@/components/OlabSingleCutSiteSVGGen";
import OlabDoubleCutSiteSVGGen from "@/components/OlabDoubleCutSiteSVGGen";
import OlabAnyCutSiteSVGGen from "@/components/OlabAnyCutSiteSVGGen";
import OlabBluntInSVGGen from "@/components/OlabBluntInSVGGen";
import OlabBluntOutSVGGen from "@/components/OlabBluntOutSVGGen";
import OlabStickyInSVGGen from "@/components/OlabStickyInSVGGen";
import OlabStickyOutSVGGen from "@/components/OlabStickyOutSVGGen";

// Access treeselect component
import Treeselect from "@riophae/vue-treeselect";
import { ASYNC_SEARCH } from "@riophae/vue-treeselect";

// Access treeselect CSS styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Clipboard } from "v-clipboard";
import $ from "jquery";

export default {
  name: "designbackbone",
  data() {
    return {
      plasmid: null,
      filteredGenes: null,
      filteredTags: null,
      selectedD3GeneData: null,
      selectedGeneDName: "",
      selectedGene: null,
      selectedSeq: null,
      selectedPlasmidID: null,
      selectedEnzyme1: null,
      selectedEnzyme2: null,
      selectedEnzymeMsg: null,
      circularMap: new OlabCircularMap("d3svgCircularMapDBB", "tooltipDBB"),
      linearMap: new OlabLinearMap(),
      searchStr: "",
      searchStrHitArr: null,
      geneNotFound: false,
      linearMapHeaderStyle: {
        "background-color": "0x0eee"
      },
      resEnzymeArr: null,
      resEnzymeStyle: {
        borderStyle: "none none solid none",
        borderColor: "#d9534f"
      },
      displayData: {
        start: "0",
        numStart: 0,
        startValid: true,
        startDisabledState: "disabled",
        startInvalidFeedback: "Start has to be an integer",
        range: "1",
        numRange: 1,
        rangeValid: true,
        rangeDisabledState: "disabled",
        rangeInvalidFeedback: "Length has to be a positive integer (>0)"
      },
      configPlasmid: true,
      configAssemblyMode: "RESTRICTION_ENZYME",
      inputHomRSeq: "",
      inputHomLSeq: "",
      homRSeq: "",
      homLSeq: "",
      homROption: "direct",
      homLOption: "direct",
      homRSeqValid: false,
      homLSeqValid: false,
      invalidSeqMessage:
        "Sequence (a,c,g,t) has to be (+) strand from 5’ to 3’ with a length of 15 bps and 60 bps",
      oneCSREnzArr: null,
      twoCSREnzArr: null,
      oneOrMoreCSREnzArr: null,
      enzymeCSNum: "one_cs",
      enzymeCSBluntIn: true,
      enzymeCSBluntOut: false,
      enzymeCSStickyIn: false,
      enzymeCSStickyOut: false,
      cutType: "single",
      cutDirection: "clockwise"
    };
  },
  components: {
    FontAwesomeIcon,
    Treeselect,
    OlabDoubleCutSVGGen,
    OlabSingleCutSVGGen,
    OlabCWCutSVGGen,
    OlabCCWCutSVGGen,
    OlabSingleCutSiteSVGGen,
    OlabDoubleCutSiteSVGGen,
    OlabAnyCutSiteSVGGen,
    OlabBluntInSVGGen,
    OlabBluntOutSVGGen,
    OlabStickyInSVGGen,
    OlabStickyOutSVGGen
  },
  props: [
    "user",
    "dbInfo",
    "selectedType",
    "selectedCart",
    "dsgn",
    "backbone",
    "processing",
    "resetMessage",
    "disabledState",
    "statusObj",
    "errorObj"
  ],
  computed: {
    filteredResEnzymes() {
      // console.log("filteredResEnzymes ...");
      let fREArr = [];
      if (this.enzymeCSNum === "any_cs" && this.oneOrMoreCSREnzArr) {
        fREArr = this.oneOrMoreCSREnzArr;
      } else if (this.enzymeCSNum === "one_cs" && this.oneCSREnzArr) {
        fREArr = this.oneCSREnzArr;
      } else if (this.enzymeCSNum === "two_cs" && this.twoCSREnzArr) {
        fREArr = this.twoCSREnzArr;
      }
      const eFREArr = [];
      fREArr.map(re => {
        // console.log("re =", re);
        if (
          (this.enzymeCSBluntIn && re.cut_type === "blunt_in") ||
          (this.enzymeCSBluntOut && re.cut_type === "blunt_out") ||
          (this.enzymeCSStickyIn && re.cut_type === "sticky_in") ||
          (this.enzymeCSStickyOut && re.cut_type === "sticky_out")
        ) {
          eFREArr.push(re);
        }
      });
      // console.log("filteredResEnzymes: eFREArr=", eFREArr);
      return eFREArr;
    },
    enzyme1Display() {
      let dpStr = this.cutType === "gibson" ? "" : "Enzyme 1: ";
      if (this.selectedEnzymeMsg && this.selectedEnzymeMsg.mes1) {
        dpStr = this.selectedEnzymeMsg.mes1;
      } else if (this.selectedEnzyme1) {
        const cutSite =
          this.selectedEnzyme1.cutSite === undefined
            ? `Cannot find ${this.selectedEnzyme1.name}'s sequence in backbone`
            : this.selectedEnzyme1.cutSite;
        dpStr = dpStr + `${this.selectedEnzyme1.name}'s cut site: ${cutSite}`;
      } else {
        dpStr = "";
      }

      if (this.cutType === "gibson" && this.selectedEnzyme1) {
        if (this.selectedEnzyme1.name === "HomL" && !this.homLSeqValid) {
          dpStr = "";
        } else if (this.selectedEnzyme1.name === "HomR" && !this.homRSeqValid) {
          dpStr = "";
        }
      }
      return dpStr;
    },
    enzyme2Display() {
      let dpStr = this.cutType === "gibson" ? "" : "Enzyme 2: ";
      if (this.selectedEnzymeMsg && this.selectedEnzymeMsg.mes2) {
        dpStr = this.selectedEnzymeMsg.mes2;
      } else if (this.selectedEnzyme2) {
        const cutSite =
          this.selectedEnzyme2.cutSite === undefined
            ? `Cannot find ${this.selectedEnzyme2.name}'s sequence in backbone`
            : this.selectedEnzyme2.cutSite;
        dpStr = dpStr + `${this.selectedEnzyme2.name}'s cut site: ${cutSite}`;
      } else {
        dpStr = "";
      }

      if (this.cutType === "gibson" && this.selectedEnzyme2) {
        if (this.selectedEnzyme2.name === "HomL" && !this.homLSeqValid) {
          dpStr = "";
        } else if (this.selectedEnzyme2.name === "HomR" && !this.homRSeqValid) {
          dpStr = "";
        }
      }
      return dpStr;
    },
    d3GeneID() {
      let id = "None";
      if (this.selectedD3GeneData) {
        id = this.selectedD3GeneData.geneID;
      }
      return id;
    },
    d3GeneStrand() {
      let strand = "";
      if (this.selectedD3GeneData) {
        strand = this.selectedD3GeneData.comp === true ? "-" : "+";
      }
      return strand;
    },
    displayStartValidState() {
      return this.displayData.startValid ? "is-valid" : "is-invalid";
    },
    displayRangeValidState() {
      return this.displayData.rangeValid ? "is-valid" : "is-invalid";
    },
    selectStartValidState() {
      return this.selectData.startValid ? "is-valid" : "is-invalid";
    },
    selectRangeValidState() {
      return this.selectData.rangeValid ? "is-valid" : "is-invalid";
    },
    homRSeqValidState() {
      return this.homRSeqValid ? "is-valid" : "is-invalid";
    },
    homLSeqValidState() {
      return this.homLSeqValid ? "is-valid" : "is-invalid";
    }
  },
  methods: {
    // NOTE: We need to act on searchChange to null out plasmid
    // is because VueTreeSelect doesn't always trigger loadOptions()
    // when searchQuery is changed. It has a buildin caching on
    // searchQuery.
    async searchChange(searchQuery) {
      // console.log("searchChange: searchQuery =", searchQuery);
      const ITEMS_PER_PAGE = 10;
      const searchBody = {
        searchString: searchQuery,
        searchField: "merge",
        includeDeleted: false,
        auxID: null,
        page: 1,
        limit: ITEMS_PER_PAGE
      };
      const plasmids = await OlabUtils.getPlasmids(searchBody);
      if (plasmids.length === 0) {
        this.resetSelectedPlasmid();
      }
    },
    async doViewGenesMap(plasmidID) {
      // console.log("doViewGenesMap: plasmidID =", plasmidID);
      if (plasmidID) {
        const ITEMS_PER_PAGE = 1;
        const searchBody = {
          searchString: plasmidID,
          searchField: "olab_id",
          includeDeleted: false,
          auxID: null,
          page: 1,
          limit: ITEMS_PER_PAGE
        };
        const plasmids = await OlabUtils.getPlasmids(searchBody);
        this.plasmid = plasmids && plasmids.length > 0 ? plasmids[0] : null;
        if (this.plasmid) {
          this.viewGenesMap();
        }
      } else {
        this.resetSelectedPlasmid();
      }
    },
    async loadOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        // console.log("ASYNC_SEARCH: searchQuery =", searchQuery);
        const ITEMS_PER_PAGE = 10;
        const searchBody = {
          searchString: searchQuery,
          searchField: "merge",
          includeDeleted: false,
          auxID: null,
          page: 1,
          limit: ITEMS_PER_PAGE
        };
        const plasmids = await OlabUtils.getPlasmids(searchBody);
        // Add new fields to plasmids to match selecttree data format
        plasmids.forEach(pld => {
          pld.id = pld.olab_id;
          pld.label = `${pld.olab_id} - ${pld.name}`;
        });
        callback(null, plasmids);
      }
    },
    evaluatePreviewDisableState() {
      // console.log("evaluatePreviewDisableState ...");
      // console.log("plasmid =", this.plasmid);
      // console.log("this.selectedEnzyme1 =", this.selectedEnzyme1);
      // console.log("this.selectedEnzyme2 =", this.selectedEnzyme2);

      let previewReady = false;
      if (this.configPlasmid === false) {
        previewReady = true;
      } else if (
        (this.cutType === "single" &&
          this.selectedEnzyme1 &&
          this.selectedEnzyme1.cutSite !== undefined) ||
        ((this.cutType === "double" || this.cutType === "gibson") &&
          this.selectedEnzyme1 &&
          this.selectedEnzyme1.cutSite !== undefined &&
          this.selectedEnzyme2 &&
          this.selectedEnzyme2.cutSite !== undefined)
      ) {
        previewReady = true;
        this.backbone.olab_id = this.plasmid.olab_id;
        this.backbone.type = "plasmid";
        this.backbone.res_enzyme1 = this.selectedEnzyme1
          ? this.selectedEnzyme1.name
          : "";
        this.backbone.cut_site1 =
          this.cutType === "gibson" ? this.selectedEnzyme1.cutSite : 0;
        this.backbone.homl_seq = this.cutType === "gibson" ? this.homLSeq : "";
        this.backbone.res_enzyme2 = this.selectedEnzyme2
          ? this.selectedEnzyme2.name
          : "";
        this.backbone.cut_site2 =
          this.cutType === "gibson" ? this.selectedEnzyme2.cutSite : 0;
        this.backbone.homr_seq = this.cutType === "gibson" ? this.homRSeq : "";
        this.backbone.cut_method = this.cutType;
        this.backbone.cut_dir_cw =
          this.cutDirection === "clockwise" ? true : false;
        this.backbone.flip_box_design = false;
        this.backbone.enzyme_preserve = false;

        // Should only send one enzyme if both cut sites are from the same enzyme
        if (this.backbone.res_enzyme1 === this.backbone.res_enzyme2) {
          this.backbone.res_enzyme2 = "";
        }

        // console.log("previewReady =", previewReady);
        // console.log("backbone =", this.backbone);
      }
      this.disabledState.preview = previewReady ? "" : "disabled";
      // console.log("disabledState.preview =", this.disabledState.preview);
    },
    resEnzymeSelectChange() {
      // NOTE: resEnzymeArr isn't the latest so we need to use watch for the
      //       latest resEnzymeArr's values
      // console.log("resEnzymeSelectChange: resEnzymeArr =", this.resEnzymeArr);
    },
    async prepCutSites() {
      const fullResEnzNameArr = [];
      this.dbInfo.db_configs.res_enzymes.map(rE => {
        fullResEnzNameArr.push(rE.name);
      });
      const fullCutSiteArr = await this.getEnzymeCS(fullResEnzNameArr);
      // console.log("fullCutSiteArr =", fullCutSiteArr);
      this.oneCSREnzArr = [];
      this.twoCSREnzArr = [];
      this.oneOrMoreCSREnzArr = [];
      this.dbInfo.db_configs.res_enzymes.map(rE => {
        const csName = rE.name;
        const cs = fullCutSiteArr[csName];
        if (cs.length > 0) {
          this.oneOrMoreCSREnzArr.push(rE);
        }
        if (cs.length === 1) {
          this.oneCSREnzArr.push(rE);
        }
        if (cs.length === 2) {
          this.twoCSREnzArr.push(rE);
        }
      });
      // console.log("oneCSREnzArr =", this.oneCSREnzArr);
      // console.log("twoCSREnzArr =", this.twoCSREnzArr);
      // console.log("oneOrMoreCSREnzArr =", this.oneOrMoreCSREnzArr);
    },
    async viewGenesMap() {
      if (this.filteredGenes) {
        this.resetMapStates();
      }
      if (this.plasmid) {
        // No need to do prep cutsites for Gibson Assembly
        if (this.configAssemblyMode !== "GIBSON") {
          // Setup cutSite Arrays
          await this.prepCutSites();
        }

        // this.filteredGenes will contain the return genes
        // this.filteredTags will contain the return tags
        await this.getMetaMap(true);
        if (
          this.filteredGenes &&
          this.filteredGenes.length === 0 &&
          this.filteredTags &&
          this.filteredTags.length === 0
        ) {
          // Try to use the older version for backward compatibility
          await this.getMetaMap(false);
        }
        this.circularMap.setup();
        // console.log("this.filteredGenes =", this.filteredGenes);
        // console.log("this.filteredTags =", this.filteredTags);

        const frameArr = this.circularMap.compute(
          this.filteredGenes,
          this.plasmid.stats.total_seq_len
        );
        // console.log("(1) frameArr =", frameArr);

        if (this.filteredTags && this.filteredTags.length > 0) {
          const tagFrameArr = this.circularMap.computeTagFrames(
            this.filteredTags,
            this.plasmid.stats.total_seq_len
          );
          // console.log("tagFrameArr =", tagFrameArr);
          // Insert tagFrameArr's array(s) to the front of frameArr
          frameArr.unshift(...tagFrameArr);
          // console.log("(2) frameArr = ", frameArr);
        }
        this.circularMap.draw(frameArr, this);

        // Setup LinearMap
        this.linearMap.setup();
      }
    },
    // Note: Split metaMap into filteredGenes and filteredTags
    async getMetaMap(useNewVersion) {
      if (this.plasmid) {
        let plasmGArr = null;
        if (useNewVersion) {
          plasmGArr = await OlabAxios.getPlasmidMetaMap(
            this.plasmid,
            this.statusObj,
            this.errorObj
          );
        } else {
          plasmGArr = await OlabAxios.getPlasmidGenes(
            this.plasmid,
            this.statusObj,
            this.errorObj
          );
        }

        // console.log("useNewVersion", useNewVersion, "plasmGArr =", plasmGArr);
        const gArr = [];
        const tArr = [];
        MetaMap.processMetaMap(plasmGArr, gArr, tArr, useNewVersion);
        for (let i = 0; i < tArr.length; i++) {
          const tObj = tArr[i];
          if (tObj && tObj.name === "HomL_Seq") {
            // console.log("HomL_Seq =", tObj);
            this.inputHomLSeq = tObj.seq;
          } else if (tObj && tObj.name === "HomR_Seq") {
            // console.log("HomR_Seq =", tObj);
            this.inputHomRSeq = tObj.seq;
          }
        }
        // console.log("gArr =", gArr, ", tArr =", tArr);
        this.filteredGenes = gArr;
        this.filteredTags = tArr;
      }
    },
    async getEnzymeCS(resEnzArr) {
      // console.log("resEnzArr =", resEnzArr);
      // console.log("plasmid =", this.plasmid);
      const cutSites = await OlabAxios.getEnzymeCutSites(
        this.plasmid,
        this.configAssemblyMode === "GIBSON" ? "gibson" : "res_enzyme",
        resEnzArr,
        this.homLSeq,
        this.homRSeq,
        this.statusObj,
        this.errorObj
      );
      // console.log("cuteSites =", cutSites);
      return cutSites;
    },
    processSelectedCutSites(resEnzymeArr, cutSites, mode) {
      // console.log("processSelectedCutSites: resEnzymeArr =", resEnzymeArr);
      // console.log("mode =", mode, ", cutSites =", cutSites);
      if (resEnzymeArr && cutSites && mode === "RESTRICTION_ENZYME") {
        let totalCS = 0;
        resEnzymeArr.map(rE => {
          totalCS += cutSites[rE].length;
        });

        // console.log("** totalCS = ", totalCS);
        if (this.cutType === "single") {
          if (totalCS === 1) {
            // Only consider the first enzyme and it has to be with one cut site
            this.selectedEnzyme1 = {
              name: resEnzymeArr[0],
              cutSite: cutSites[resEnzymeArr[0]][0]
            };
            // Clear message
            this.selectedEnzymeMsg = null;

            // Done! We can compute arms' primers
          } else {
            // console.log(
            //   "Only consider the first enzyme and it has to be with one cut site"
            // );
            this.selectedEnzymeMsg = {
              mes1: "Single Cut Design cannot support more than 1 cut site.",
              mes2: `Total Cut Sites = ${totalCS}`
            };
            // Clear old selected enzyme1
            this.selectedEnzyme1 = null;
          }
          // Clear old selected enzyme2
          this.selectedEnzyme2 = null;
        } else {
          // Handle double cut case (this.cutType === "double")
          if (totalCS === 1) {
            this.selectedEnzyme1 = {
              name: resEnzymeArr[0],
              cutSite: cutSites[resEnzymeArr[0]][0]
            };
            this.selectedEnzymeMsg = {
              mes1: null,
              mes2: "Please select the second enzyme"
            };
          } else if (totalCS === 2) {
            // Only consider the first two cut sites of the enzyme list
            this.selectedEnzyme1 = {
              name: resEnzymeArr[0],
              cutSite: cutSites[resEnzymeArr[0]][0]
            };
            if (cutSites[resEnzymeArr[0]].length > 1) {
              // Get it from the first enzyme if it has more than one cut site
              this.selectedEnzyme2 = {
                name: resEnzymeArr[0],
                cutSite: cutSites[resEnzymeArr[0]][1]
              };
            } else {
              this.selectedEnzyme2 = {
                name: resEnzymeArr[1],
                cutSite: cutSites[resEnzymeArr[1]][0]
              };
            }
            // Sort enzymes if necessary: lower cut site first
            if (this.selectedEnzyme1.cutSite > this.selectedEnzyme2.cutSite) {
              const tmpEnz = this.selectedEnzyme1;
              this.selectedEnzyme1 = this.selectedEnzyme2;
              this.selectedEnzyme2 = tmpEnz;
            }

            // Clear message
            this.selectedEnzymeMsg = null;

            // Done! We can compute arms' primers
          } else {
            // Generate unsupported message
            this.selectedEnzymeMsg = {
              mes1: "Double Cut Design cannot support more than 2 cut sites.",
              mes2: `Total Cut Sites = ${totalCS}`
            };
            // Clear old selected enzymes
            this.selectedEnzyme1 = null;
            this.selectedEnzyme2 = null;
          }
        }
      } else if (cutSites && mode === "GIBSON") {
        this.selectedEnzyme1 = {
          name: "HomL",
          cutSite: cutSites["HomL"][0]
        };
        this.selectedEnzyme2 = {
          name: "HomR",
          cutSite: cutSites["HomR"][0]
        };
        // TODO: DISCUSS THIS SWAP REQUIREMENT WITH BIO SERVER IMPLEMENTATION
        if (this.selectedEnzyme1.cutSite > this.selectedEnzyme2.cutSite) {
          this.cutDirection = "clockwise";
          const temp = this.selectedEnzyme1;
          this.selectedEnzyme1 = this.selectedEnzyme2;
          this.selectedEnzyme2 = temp;
        } else {
          this.cutDirection = "counter_clockwise";
        }
        // console.log("cutDirection =", this.cutDirection);

        // Clear message
        this.selectedEnzymeMsg = null;
      } else {
        this.selectedEnzyme1 = null;
        this.selectedEnzyme2 = null;
        this.selectedEnzymeMsg = null;
      }
    },
    computeBBFrame(enz1, enz2, dir, chrmLen) {
      // console.log("computeBBFrame: enz1 =", enz1, ", enz2 =", enz2);
      let bbRegionFrame = [];
      if (enz1 && enz2) {
        // console.log("dir =", dir, ", chrmLen =", chrmLen);
        bbRegionFrame = this.circularMap.computeBBRegion(
          enz1,
          enz2,
          dir,
          chrmLen
        );
      }
      // console.log("bbRegionFrame =", bbRegionFrame);
      return bbRegionFrame;
    },
    async processResEnzymeArr(resEnzymeArr) {
      // console.log("** processResEnzymeArr =", resEnzymeArr);
      const cutSites = await this.getEnzymeCS(resEnzymeArr);
      this.processSelectedCutSites(
        resEnzymeArr,
        cutSites,
        this.configAssemblyMode
      );

      // console.log("selectedEnzyme1 =", this.selectedEnzyme1);
      // console.log("selectedEnzyme2 =", this.selectedEnzyme2);
      if (
        this.selectedEnzyme1 &&
        this.selectedEnzyme1.cutSite !== undefined &&
        this.selectedEnzyme2 &&
        this.selectedEnzyme2.cutSite !== undefined
      ) {
        const bbRegionFrame = this.computeBBFrame(
          this.selectedEnzyme1,
          this.selectedEnzyme2,
          this.cutDirection,
          this.plasmid.stats.total_seq_len
        );
        this.circularMap.drawBBRegion(bbRegionFrame);
      } else {
        // Clear backbone region
        this.circularMap.drawBBRegion([]);
      }

      const resEnzCSFrame = this.circularMap.computeEnzymeCS(
        resEnzymeArr,
        cutSites,
        this.plasmid.stats.total_seq_len
      );
      // console.log("resEnzCSFrame =", resEnzCSFrame);
      this.circularMap.drawEnzymeCS(resEnzCSFrame);

      // Evaluate Preview disable state
      this.evaluatePreviewDisableState();
    },
    async processSelectedGene(geneID) {
      // console.log("processSelectedGene: geneID =", geneID);
      if (geneID) {
        const searchBody = OlabSearch.createGeneSearchBody(
          geneID,
          "olab_id",
          null,
          1,
          1
        );
        const geneArr = await OlabUtils.getGenes(searchBody);
        let gn = null;
        if (geneArr.length > 0) {
          this.geneNotFound = false;
          gn = geneArr[0];
          // console.log("gn =", gn);
          // TODO: Need to handle gene.isJoined === true case
          const gnSeq = gn.sequence.seq;
          this.searchStrHitArr = this.linearMap.draw(gnSeq, this);
        } else {
          // console.log(
          //   "Cannot access gene or gene not found: olab_id = ",
          //   geneID
          // );
          this.geneNotFound = true;
        }
        return gn;
      }
    },
    async processGeneD3Data(setGeneName) {
      const isVisible = $("#d3LinearMap").is(":visible");
      // console.log("d3LinearMap: isVisible = " + isVisible);
      if (this.selectedD3GeneData) {
        const gnSeq = await this.processSelectedGene(
          this.selectedD3GeneData.geneID
        );
        if (this.selectedGene) {
          // Setup selectedSeq and display data
          // Set gene's seqeunce and selectedSeq
          // console.log("selectedGene =", this.selectedGene);
          this.selectedGene.sequence.seq = gnSeq.sequence.seq;
          this.selectedSeq = this.selectedGene.sequence.seq;
          // console.log("selectedSeq =", this.selectedSeq);
          this.displayData.start = 0;
          this.displayData.range = this.selectedSeq.length;
          this.setDisplayStart(this.displayData.start);
          this.setDisplayRange(this.displayData.range);
        }
        this.linearMapHeaderStyle = {
          "background-color": this.selectedD3GeneData.geneFillColor
        };
        if (setGeneName) {
          // Set gene's data on UI
          this.selectedGeneDName = this.selectedD3GeneData.name;
        }
        if (!isVisible) {
          $("#d3LinearMap").collapse("toggle");
        }
      } else {
        this.selectedGene = null;
        this.linearMap.removeSeqGroup();
        if (isVisible) {
          $("#d3LinearMap").collapse("toggle");
        }
      }
    },
    getSelectedGene(dName) {
      if (dName && this.filteredGenes) {
        for (let i = 0; i < this.filteredGenes.length; i++) {
          if (this.filteredGenes[i].d_name === dName) {
            this.selectedGene = this.filteredGenes[i];
            console.log("Gene found! selectedGene =", this.selectedGene);
            break;
          }
        }
      }
    },
    // Function to get the Selected Text
    _getHLSeq() {
      let hlSeq = "";

      // window.getSelection
      if (window.getSelection) {
        hlSeq = window.getSelection();
        // console.log("(1) hlSeq =", hlSeq);
      }
      // document.getSelection
      else if (document.getSelection) {
        hlSeq = document.getSelection();
        // console.log("(2) hlSeq =", hlSeq);
      }
      // document.selection
      else if (document.selection) {
        hlSeq = document.selection.createRange().text;
        // console.log("(3) hlSeq =", hlSeq);
      } else return;
      // Remove spaces from the selected text before return
      const seqStr = hlSeq.toString();
      return seqStr.replace(/\s/g, "");
    },
    copySelectSeq() {
      const clipboardText = this._getHLSeq();
      // console.log("clipboardText =", clipboardText);
      Clipboard.copy(clipboardText);
    },
    computeDisplaySeq: async function(start, range) {
      const res = await OlabBlock.computeSequence(
        this.selectedGene,
        start,
        range,
        this.statusObj,
        this.errorObj
      );
      if (res.status === "success") {
        this.selectedSeq = res.seqFrag;
        // console.log("** selectedSeq =", this.selectedSeq);
        this.searchStrHitArr = this.linearMap.draw(this.selectedSeq, this);
      } else {
        // console.log(res);
        if (res.failType === "start") {
          this.displayData.startValid = false;
          this.displayData.startInvalidFeedback = res.message;
        } else {
          // "range" case
          this.displayData.rangeValid = false;
          this.displayData.rangeInvalidFeedback = res.message;
        }
      }
    },
    setDisplayStart: function(start) {
      const numType = typeof start === "number";
      this.displayData.startValid = numType
        ? true
        : start.toString().match(/^[-+]?(0|[1-9]\d*)$/) != null;
      if (this.displayData.startValid) {
        this.displayData.numStart = parseInt(start);
      } else {
        this.displayData.startInvalidFeedback = "Start has to be an integer";
      }
    },
    setDisplayRange: function(range) {
      const numType = typeof range === "number";
      this.displayData.rangeValid = numType
        ? true
        : range.toString().match(/^[1-9]\d*$/) != null;
      if (this.displayData.rangeValid) {
        const tmpRange = parseInt(range);
        if (tmpRange > this.plasmid.stats.total_seq_len) {
          this.displayData.rangeValid = false;
          this.displayData.rangeInvalidFeedback = `Length can not be greater than plasmid's length: ${this.plasmid.stats.total_seq_len}`;
        } else if (tmpRange > OlabLinearMap.MAX_CHARS_PER_MAP) {
          this.displayData.rangeValid = false;
          this.displayData.rangeInvalidFeedback = `Length can not be greater than ${OlabLinearMap.MAX_CHARS_PER_MAP}`;
        } else {
          this.displayData.numRange = tmpRange;
        }
      } else {
        this.displayData.rangeInvalidFeedback =
          "Length has to be a positive integer (>0)";
      }
    },
    resetSelectedPlasmid: function() {
      this.plasmid = null;
      this.selectedPlasmidID = null;
      this.resetMapStates();
    },
    resetMapStates: function() {
      // console.log("Calling resetMapStates ...", this.plasmid);
      // Reset selected fields
      this.selectedGeneDName = "";
      this.filteredGenes = null;
      this.filteredTags = null;
      this.selectedD3GeneData = null;
      this.resEnzymeArr = null;
      this.circularMap.reset();
      this.geneNotFound = false;
      this.cutType = "single";
      this.cutDirection = "clockwise";
      // this.oneCSREnzArr = null;
      // this.twoCSREnzArr = null;
      // this.oneOrMoreCSREnzArr = null;
      this.enzymeCSNum = "one_cs";
      this.selectedEnzyme1 = null;
      this.selectedEnzyme2 = null;
      this.selectedEnzymeMsg = null;
      this.disabledState.preview = "disabled";
      // TODO: Need to refactor backbone object into a class
      this.backbone.olab_id = "";
      this.backbone.res_enzyme1 = "";
      this.backbone.res_enzyme2 = "";
      this.backbone.cut_method = "single";
      this.backbone.cut_dir_cw = true;
      this.backbone.flip_box_design = false;
      this.backbone.enzyme_preserve = false;
      this.backbone.cut_site1 = 0;
      this.backbone.cut_site2 = 0;
      this.backbone.homl_seq = "";
      this.backbone.homr_seq = "";

      // Gibson Assembly
      this.inputHomLSeq = "";
      this.inputHomRSeq = "";
      this.homLSeq = "";
      this.homRSeq = "";
      this.homLOption = "direct";
      this.homROption = "direct";
    },
    setViewSequence() {
      // Set default sequence if selected gene isn't selected
      if (this.selectedGeneDName === "" && this.filteredGenes) {
        this.selectedGeneDName = this.filteredGenes[0].d_name;
      }
    },
    validateSeq(seq) {
      let state = false;
      // Make sure seq.length is between 15 and 60
      state = seq && seq.length >= 15 && seq.length <= 60;
      state = state && !seq.match(/[^acgt]/gi);
      return state;
    },
    processHomOption(homOption, homSeq) {
      let convertSeq = "";
      if (homSeq) {
        switch (homOption) {
          case "reverse":
            convertSeq = OlabPrimer.reverseSeq(homSeq);
            break;
          case "complement":
            convertSeq = OlabPrimer.complementSeq(homSeq);
            break;
          case "reverse_complement":
            convertSeq = OlabPrimer.complementSeq(homSeq);
            convertSeq = OlabPrimer.reverseSeq(convertSeq);
            break;
          default:
            convertSeq = homSeq;
        }
      }
      return convertSeq;
    },
    computeHomLSeq() {
      // console.log("** inputHomLSeq = " + this.inputHomLSeq);
      if (this.configAssemblyMode === "GIBSON" && this.inputHomLSeq) {
        this.inputHomLSeq = this.inputHomLSeq.toUpperCase();
        // only keep a-zA-Z and remove other characters from seq
        this.inputHomLSeq = this.inputHomLSeq.replace(/[^a-zA-Z]/g, "");
        this.homLSeqValid = this.validateSeq(this.inputHomLSeq);
        this.homLSeq = this.processHomOption(
          this.homLOption,
          this.inputHomLSeq
        );
        if (this.homLSeqValid && this.homRSeqValid) {
          this.processResEnzymeArr(["HomL", "HomR"]);
        }
      } else if (!this.inputHomLSeq) {
        this.homLSeqValid = false;
      }
      if (!this.homLSeqValid) {
        this.disabledState.preview = "disabled";
      }
    },
    computeHomRSeq() {
      // console.log("** inputHomRSeq = " + this.inputHomRSeq);
      if (this.configAssemblyMode === "GIBSON" && this.inputHomRSeq) {
        this.inputHomRSeq = this.inputHomRSeq.toUpperCase();
        // only keep a-zA-Z and remove other characters from seq
        this.inputHomRSeq = this.inputHomRSeq.replace(/[^a-zA-Z]/g, "");
        this.homRSeqValid = this.validateSeq(this.inputHomRSeq);
        this.homRSeq = this.processHomOption(
          this.homROption,
          this.inputHomRSeq
        );
        if (this.homRSeqValid && this.homLSeqValid) {
          this.processResEnzymeArr(["HomL", "HomR"]);
        }
      } else if (!this.inputHomRSeq) {
        this.homRSeqValid = false;
      }
      if (!this.homRSeqValid) {
        this.disabledState.preview = "disabled";
      }
    }
  },
  watch: {
    homLOption() {
      this.computeHomLSeq();
    },
    homROption() {
      this.computeHomRSeq();
    },
    inputHomRSeq() {
      this.computeHomRSeq();
    },
    inputHomLSeq() {
      this.computeHomLSeq();
    },
    configAssemblyMode() {
      // console.log("Watch: configAssemblyMode =", this.configAssemblyMode);
      this.resetMapStates();
      this.cutType = this.configAssemblyMode === "GIBSON" ? "gibson" : "single";
      this.doViewGenesMap(this.selectedPlasmidID);
    },
    configPlasmid() {
      // console.log("configPlasmid =", this.configPlasmid);
      if (this.configPlasmid === false) {
        this.resetSelectedPlasmid();
      }
      this.evaluatePreviewDisableState();
    },
    cutType() {
      // console.log("cutType change ..., ", this.cutType);
      if (this.resEnzymeArr && this.resEnzymeArr.length > 0) {
        this.processResEnzymeArr(this.resEnzymeArr);
      }
    },
    cutDirection() {
      // console.log("cutDirection change ..., ", this.cutDirection);
      if (this.resEnzymeArr && this.resEnzymeArr.length > 0) {
        this.processResEnzymeArr(this.resEnzymeArr);
      }
    },
    resEnzymeArr() {
      // console.log("*** watch: resEnzymeArr =", this.resEnzymeArr);
      this.resEnzymeStyle.borderColor = "#d9534f";
      if (this.resEnzymeArr && this.resEnzymeArr.length > 0) {
        this.resEnzymeStyle.borderColor = "#5cb85c";
        if (this.resEnzymeArr.length === 1) {
          // Clear old selectedEnzyme2
          this.selectedEnzyme2 = null;
        }
        this.processResEnzymeArr(this.resEnzymeArr);
      } else {
        // remove all previous enzyme arcs and labels
        this.circularMap.removeFrameEnzRing();
        this.circularMap.removeFrameEnzLabel();
        // remove previous selected cut sites
        this.selectedEnzyme1 = null;
        this.selectedEnzyme2 = null;
        this.selectedEnzymeMsg = null;
        this.evaluatePreviewDisableState();
      }
    },
    selectedPlasmidID() {
      // console.log("selectedPlasmidID =", this.selectedPlasmidID);
      this.doViewGenesMap(this.selectedPlasmidID);
    },
    selectedD3GeneData() {
      // set gene name in this call
      this.processGeneD3Data(true);
    },
    selectedGeneDName() {
      // console.log("Watch: selectedGeneDName =", this.selectedGeneDName);
      if (this.selectedGeneDName == "") {
        return;
      }
      this.getSelectedGene(this.selectedGeneDName);

      // Show linear sequence map
      this.selectedD3GeneData = this.circularMap.getGeneArc(
        this.selectedGene.olab_id
      );
      // Do not set gene name in this call
      this.processGeneD3Data(false);
    },
    "displayData.start": async function() {
      if (!this.selectedD3GeneData) return;
      this.setDisplayStart(this.displayData.start);
      await this.computeDisplaySeq(
        this.displayData.numStart,
        this.displayData.numRange
      );
    },
    "displayData.range": async function() {
      if (!this.selectedD3GeneData) return;
      this.setDisplayRange(this.displayData.range);
      await this.computeDisplaySeq(
        this.displayData.numStart,
        this.displayData.numRange
      );
    },
    searchStr() {
      // Remove all space characters from search string
      this.searchStr = this.searchStr.replace(/\s/g, "");

      this.searchStrHitArr = this.linearMap.searchSeqBlock(
        this.searchStr,
        false
      );
      // console.log("searchStrHitArr =", this.searchStrHitArr);
    }
  }
};
</script>

<style lang="scss" scoped>
#d3svgCircularMapDBB {
  margin: 15px auto;
  width: 500px;
  position: relative;
}

#d3svgLinearMap {
  margin: 25px auto;
  width: 600px;
  position: relative;
  display: none;
}

.labels {
  font-family: sans-serif;
  font-size: 12px;
  text-anchor: middle;
}

#tooltipDBB {
  top: 200px;
  left: 210px;
  text-align: left;
  font-size: 12px;
  // border: 1px dashed #5bc0de;
  position: absolute;
  padding: 10px;
  background-color: #efc;
  display: none;
}

#tooltipDBB .display-name {
  text-decoration: underline;
}

#enzymeTooltip {
  top: 200px;
  left: 210px;
  text-align: left;
  font-size: 12px;
  // border: 1px dashed #5bc0de;
  position: absolute;
  padding: 10px;
  background-color: #efc;
  display: none;
}

#enzymeTooltip .display-name {
  text-decoration: underline;
}
</style>
