import { render, staticRenderFns } from "./OlabPlasmidMap.vue?vue&type=template&id=cf00c2ba&scoped=true&"
import script from "./OlabPlasmidMap.vue?vue&type=script&lang=js&"
export * from "./OlabPlasmidMap.vue?vue&type=script&lang=js&"
import style0 from "./OlabPlasmidMap.vue?vue&type=style&index=0&id=cf00c2ba&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf00c2ba",
  null
  
)

export default component.exports